import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth } from '../auth/authProvider';

const PrivateRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  const { isAuthenticated } = useAuth();
  console.log("isAuthenticated",isAuthenticated)
  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default PrivateRoute;
