import axios from "axios";

interface AuthResponse {
    accessToken?: string;
    message?: string;
    user:{
        accessToken: string;
        user: { email: string; id: number; };
        message: string;
        email?: string;
        id?: Number;
    }
    // Add other user properties if needed
  }
  export const authenticate = async (email: String,password: String) => {
    
    
      try {
        const response = await fetch('http://localhost:5000/api/auth/login', {
          method: 'POST',
          credentials: "include",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });
  
        if (!response.ok) {
          throw new Error('Authentication failed');
        }
  
        const data: AuthResponse = await response.json();
        if (data.user.email) {
            localStorage.setItem('accessToken', JSON.stringify(data.accessToken||""));
          return data.user; // User is authenticated, return the email
        } else {
          return false; // User is not authenticated
        }
      } catch (err) {
      //  console.error(err);
       // setError('Authentication failed. Please try again.');
        return false;
      }
    };
export const authenticateUser =async () =>{
    let url = window.location.href;
    let userFound = false;
   userFound = await fetch("http://localhost:5000/api/auth/login",{
   // fetch("http://localhost:5000/api/auth/register",{    
        method: "POST",
        headers: { 'Content-Type': 'application/json',},
        body: JSON.stringify({ email: "ashishsonakiya3131@gmail.com",password:"123456789"}),
    }).then((res)=>{
        
        return res;
    })
    .then((e)=>e.json())
    .then((e)=>{
        return true
    })
    return userFound;
}