import React, { createContext, useContext, useEffect, useState } from 'react';

interface AuthContextType {
  isAuthenticated: boolean;
  userDetails: object | null;
  login: (userDetails: object) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(localStorage.getItem('accessToken') ? true : false);
  const [userDetails, setUserDetails] = useState<object | null>(null);

  /*useEffect(() => {
    const token = localStorage.getItem('accessToken'); // Check token here
    console.log("token",token)
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);
*/
 // const isAuthenticated = localStorage.getItem('accessToken') ? true : false;

  const login = (userDetails: object) => {
    setUserDetails(userDetails);
    setIsAuthenticated(true);
    //localStorage.setItem('accessToken', userDetails.accessToken); // Save token on login
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserDetails(null);
    localStorage.removeItem('accessToken'); // Clear token on logout
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userDetails, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
